

































































































































































import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import {
  SelectionChangedEvent,
  GridReadyEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { maxBy, sumBy } from 'lodash';
import DateRangeFilter from '@/components/date-range-filter.vue';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';
import Tooltip from '@/components/tooltip.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import RouterService from '@/services/router.service';
import ContentDialog from '@/components/content-dialog.vue';
import { DateHelper } from '@/utils/helpers/date-helper';
import { ArrayHelper } from '@/utils/helpers/array-helper';
import ClientModel from '@/models/crm/client.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import FinancialInformationModel from '@/models/crm/financial-information.model';
import FinancialInformationService from '@/services/crm/financial-information.service';
import SettingsModel from '@/models/crm/settings.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import SettingsService from '@/services/crm/settings.service';
import dayjs from '@/plugins/dayjs';
import { NumberHelper } from '@/utils/helpers/number-helper';
import CrmEmailTituloView from '@/components/crm/titulo-email-view.vue';
import { FinancialInformationEnum } from '@/enums/crm/financial-information.enum';
import FinancialInformationOccurrenceModel from '@/models/crm/financial-information-occurrences.model';

type DataGridFilterConfig = {
  keyword: string | undefined;
  issuance: (Date | undefined)[];
  expire: (Date | undefined)[];
  expired: true | false;
  situation: string;
};

type FeaturedCard = {
  title: string;
  value: string;
  class?: string;
  cols: {
    lg: number;
    md: number;
  };
};

@Component({
  components: {
    DataGridFilter,
    DateRangeFilter,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
    CrmEmailTituloView,
  },
})
export default class CrmFinancialInformations extends Vue {
  @inject(InjectionIdEnum.CrmFinancialInformationService)
  private financialInformationService!: FinancialInformationService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  @InjectReactive('userContactInfo') readonly userContactInfo!: UserContactInfo;

  settings: SettingsModel | null = null;

  grid: GridReadyEvent | null = null;

  situations = [FinancialInformationEnum.Todos];

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'dataVencimento', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.financialInformation.grid.contractName')}`,
        field: 'titulo',
        minWidth: 130,
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.financialInformation.grid.holder')}`,
        field: 'nomePortador',
      },
      {
        headerName: `${this.$t('crm.view.financialInformation.grid.origin')}`,
        field: 'nomeDataSource',
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.financialInformation.grid.situation')}`,
        field: 'situacao',
        autoHeight: true,
      },
      {
        headerName: `${this.$t('crm.view.financialInformation.grid.issuanceDate')}`,
        field: 'dataEmissao',
        maxWidth: 155,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.financialInformation.grid.dueDate')}`,
        field: 'dataVencimento',
        maxWidth: 155,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.financialInformation.grid.debtSettlementDate')}`,
        field: 'dataBaixa',
        maxWidth: 155,
        valueGetter: (params: ValueGetterParams): string => {
          const isoDate = GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString);
          const isRowPinned = params.node && params.node.rowPinned;
          return isoDate || (!isRowPinned ? `${this.$t('crm.view.financialInformation.defaulting')}` : '');
        },
        valueFormatter: (params: ValueFormatterParams): string => {
          if (dayjs(params.value).isValid()) {
            return DateHelper.formatToLocale(params.value);
          }
          return params.value;
        },
        cellClass: ['dateISO', 'redCell'],
        cellStyle: (params): IKeyValue => ({ color: !params.data.dataBaixa ? 'red' : 'inherit' }),
      },
      {
        headerName: `${this.$t('crm.view.financialInformation.grid.defaultingDays')}`,
        field: 'qtdeDiasAtraso',
        type: 'rightAligned',
        maxWidth: 80,
        cellStyle: (params): IKeyValue => ({ color: !params.data.dataBaixa ? 'red' : 'inherit' }),
      },
      {
        headerName: `${this.$t('crm.view.financialInformation.grid.netValue')}`,
        field: 'valorLiquido',
        type: 'rightAligned',
        maxWidth: 140,
        valueFormatter: (params): string => NumberHelper.formatToLocaleDecimal(params.value),
      },
      {
        headerName: `${this.$t('crm.view.financialInformation.grid.occurrences')}`,
        maxWidth: 100,
        cellStyle: {
          'justify-content': 'center',
        },
        cellRenderer: (params): string => {
          if (params.node.rowPinned) {
            return '';
          }
          return '<span class="v-icon mdi mdi-clipboard-text theme--light"></span>';
        },
        onCellClicked: (event) => {
          if (event.data.reason == null && !event.node.rowPinned) {
            this.onOpenOccurrence(event.data.titulo);
          }
        },
      },
    ],
  };

  filters: DataGridFilterConfig = {
    keyword: undefined,
    issuance: [undefined, undefined],
    expire: [undefined, undefined],
    expired: false,
    situation: '',
  };

  items: FinancialInformationModel[] = [];

  allItems: FinancialInformationModel[] = [];

  selected: FinancialInformationModel[] = [];

  itemsOccurrence: FinancialInformationOccurrenceModel[] = [];

  predefinedIssuanceFilterRanges: IDateRangeConfig[] = this.getDateRanges();

  openModalEmail = false;

  openModalOccurrence = false;

  async mounted(): Promise<void> {
    const currentYearPeriod = DateHelper.getCurrentYearPeriod();
    this.filters.issuance = [currentYearPeriod.start, currentYearPeriod.end];
    this.filters.expire = [currentYearPeriod.start, currentYearPeriod.end];

    this.loadItems();

    this.settings = await this.settingsService.getSettings();
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: FinancialInformationModel[]): void {
    this.selected = selected;
  }

  async onOpen(): Promise<void> {
    const loader = this.$loading.show();
    try {
      let titulos = '';
      let codCliente = '';

      this.selected.forEach((select) => {
        titulos += select.titulo.concat(',');
        codCliente = select.codCliente;
      });

      await this.financialInformationService.getTitulos(titulos.substring(0, titulos.length - 1), codCliente)
        .then((urls) => {
          urls.forEach((url) => {
            window.open(url, '_blank');
          });
        });
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  onSendEmail(): void {
    this.openModalEmail = true;
  }

  onExport(selected: FinancialInformationModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.filteredItems.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Informações Financeiras',
        fileName: FinancialInformationService.generateFinancialInformationExportFilename(new Date()),
      });
    }
  }

  get filteredItems(): FinancialInformationModel[] {
    let filteredItems = this.allItems;

    if (this.filters.keyword) {
      const columnsToSearch = ['titulo', 'nomeDataSource', 'nomePortador', 'valorLiquido', 'situacao'];
      filteredItems = ArrayHelper.filterByKeyword(filteredItems, columnsToSearch, this.filters.keyword);
    }

    if (this.filters.expired) {
      const today = dayjs();
      filteredItems = filteredItems.filter((item) => dayjs(item.dataVencimento).isBefore(today));
    }

    if (this.filters.situation !== '' && this.filters.situation !== FinancialInformationEnum.Todos) {
      filteredItems = ArrayHelper.filterByKeyword(filteredItems, ['situacao'], this.filters.situation);
    }

    if (this.filters.issuance && this.filters.issuance[0]) {
      const start = dayjs(this.filters.issuance[0]);
      filteredItems = filteredItems.filter(
        (item) => dayjs(item.dataEmissao).isAfter(start) || dayjs(item.dataEmissao).isSame(start),
      );
    }

    if (this.filters.issuance && this.filters.issuance[1]) {
      const end = dayjs(this.filters.issuance[1]);
      filteredItems = filteredItems.filter(
        (item) => dayjs(item.dataEmissao).isBefore(end) || dayjs(item.dataEmissao).isSame(end),
      );
    }

    if (this.filters.expire && this.filters.expire[0]) {
      const start = dayjs(this.filters.expire[0]);
      filteredItems = filteredItems.filter(
        (item) => dayjs(item.dataVencimento).isAfter(start) || dayjs(item.dataVencimento).isSame(start),
      );
    }

    if (this.filters.expire && this.filters.expire[1]) {
      const start = dayjs(this.filters.expire[1]);
      filteredItems = filteredItems.filter(
        (item) => dayjs(item.dataVencimento).isBefore(start) || dayjs(item.dataVencimento).isSame(start),
      );
    }
    return filteredItems;
  }

  get featuredCards(): FeaturedCard[] {
    const allOverdueItems = this.allItems.filter((x) => !x.dataBaixa && x.qtdeDiasAtraso);
    const overdueItems = this.allItems.filter((x) => !x.dataBaixa && x.qtdeDiasAtraso);
    const paidLateItems = this.allItems.filter((x) => x.dataBaixa && x.qtdeDiasAtraso);
    const oldestOpenItem = maxBy(allOverdueItems, 'qtdeDiasAtraso');

    const client = this.activeClient;
    const creditLimit = client?.limiteCredito || 0;
    const orderPlusDocuments = (client?.totalVlrTitulosAberto || 0) + (client?.totalVlrPedidosAberto || 0);
    const creditLimitBalance = creditLimit - orderPlusDocuments;

    const totals = {
      creditLimitBalance,
      creditLimit,
      orderPlusDocuments,
      overdueValue: sumBy(overdueItems, 'valorLiquido'),
      defaultingDays: (oldestOpenItem && oldestOpenItem.qtdeDiasAtraso) || 0,
      paidLate: paidLateItems.length,
    };

    return [
      {
        title: `${this.$t('crm.view.financialInformation.card.creditLimitBalance')}`,
        value: `${this.$n(totals.creditLimitBalance, 'currency')}`,
        class: totals.creditLimitBalance < 0 ? 'red--text' : '',
        cols: { lg: 2, md: 4 },
      },
      {
        title: `${this.$t('crm.view.financialInformation.card.creditLimit')}`,
        value: `${this.$n(totals.creditLimit, 'currency')}`,
        cols: { lg: 2, md: 4 },
      },
      {
        title: `${this.$t('crm.view.financialInformation.card.orderPlusDocuments')}`,
        value: `${this.$n(totals.orderPlusDocuments, 'currency')}`,
        cols: { lg: 2, md: 4 },
      },
      {
        title: `${this.$t('crm.view.financialInformation.card.defaultingDays')}`,
        value: totals.defaultingDays.toString(),
        class: 'text--primary',
        cols: { lg: 2, md: 4 },
      },
      {
        title: `${this.$t('crm.view.financialInformation.card.overdueValue')}`,
        value: `${this.$n(totals.overdueValue, 'currency')}`,
        class: 'text--primary',
        cols: { lg: 2, md: 4 },
      },
      {
        title: `${this.$t('crm.view.financialInformation.card.paidLateQuantity')}`,
        value: totals.paidLate.toString(),
        cols: { lg: 2, md: 4 },
      },
    ];
  }

  get summary(): IKeyValue<number>[] | undefined {
    const items = this.filteredItems;

    if (!items || !items.length) {
      return undefined;
    }

    return [
      {
        valorLiquido: sumBy(items, 'valorLiquido'),
      },
    ];
  }

  get activeFilters(): number {
    let active = 0;
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      switch (key) {
        case 'issuance':
          if (this.filters[key] && (this.filters[key][0] || this.filters[key][1])) {
            active += 1;
          }
          break;
        default:
          if (this.filters[key]) {
            active += 1;
          }
      }
    });

    return active;
  }

  private getDateRanges(): IDateRangeConfig[] {
    return [
      {
        name: `${this.$t('global.currentMonth')}`,
        ...DateHelper.getCurrentMonthPeriod(),
      },
      {
        name: `${this.$t('global.lastMonth')}`,
        ...DateHelper.getLastMonthsPeriod(1),
      },
      {
        name: `${this.$t('global.lastThreeMonths')}`,
        ...DateHelper.getLastMonthsPeriod(3),
      },
      {
        name: `${this.$t('global.lastSixMonths')}`,
        ...DateHelper.getLastMonthsPeriod(6),
      },
      {
        name: `${this.$t('global.lastYear')}`,
        ...DateHelper.getLastYearsPeriod(1),
      },
      {
        name: `${this.$t('global.currentYear')}`,
        ...DateHelper.getCurrentYearPeriod(),
      },
    ];
  }

  private async loadItems(): Promise<void> {
    const loader = this.$loading.show();
    try {
      // get items from last 10 years to show overdue payments (days)
      this.allItems = await this.financialInformationService.getContracts(
        this.getClientIdFromRoute(), dayjs().subtract(10, 'year').toDate().toISOString(),
      );
      this.allItems.forEach((item) => {
        if (!this.situations.some((situacao) => situacao.toLowerCase() === item.situacao.toLowerCase())) {
          this.situations.push(item.situacao as FinancialInformationEnum);
        }
        this.situations.sort((a, b) => {
          if (a === FinancialInformationEnum.Todos) {
            return -1;
          }
          if (b === FinancialInformationEnum.Todos) {
            return 1;
          }
          return a.localeCompare(b);
        });
      });
      this.items = this.allItems.filter((info) => (
        (dayjs(info.dataEmissao).isAfter(dayjs(this.filters.issuance[0]))
        || dayjs(info.dataEmissao).isSame(dayjs(this.filters.issuance[0])))
        && (dayjs(info.dataEmissao).isBefore(dayjs(this.filters.issuance[1]))
        || dayjs(info.dataEmissao).isSame(dayjs(this.filters.issuance[1])))
        && (dayjs(info.dataVencimento).isAfter(dayjs(this.filters.expire[0]))
        || dayjs(info.dataVencimento).isSame(dayjs(this.filters.expire[0])))
        && (dayjs(info.dataVencimento).isBefore(dayjs(this.filters.expire[1]))
        || dayjs(info.dataVencimento).isSame(dayjs(this.filters.expire[1])))
      ));
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  private getClientIdFromRoute(): string {
    if (!this.activeClient) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    return this.activeClient.cnpjCpf;
  }

  onFilterChange(): void {
    this.loadItems();
  }

  async onOpenOccurrence(titulo: string): Promise<void> {
    this.itemsOccurrence = await this.financialInformationService.getOccurrences(
      this.getClientIdFromRoute(), titulo,
    );

    if (this.itemsOccurrence && this.itemsOccurrence.length > 0) {
      this.itemsOccurrence = this.itemsOccurrence
        .sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime());
    }

    this.openModalOccurrence = true;
  }

  dateFormatted!: Date;

  formatDate(date: Date): string {
    this.dateFormatted = date;
    return DateHelper.formatToLocale(this.dateFormatted);
  }
}
